import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CONFIRMATIONS } from '../../../../../utility/SourceAPI'

export const getTodo = createAsyncThunk('appTodo/getTodo', async () => {
    const response = await axios.get(CONFIRMATIONS)
    return {
        data: response.data
    }
})
export const getChecked = createAsyncThunk('appTodo/getChecked', async () => {
    const response = await axios.get(CONFIRMATIONS.concat('?status=checked'))
    return {
        data: response.data
    }
})
export const getConfirmed = createAsyncThunk('appTodo/getConfirmed', async () => {
    const response = await axios.get(CONFIRMATIONS.concat('?status=confirmed'))
    return {
        data: response.data
    }
})

export const addConfirmation = createAsyncThunk('appTodo/addConfirmation', async (confirmation, { dispatch }) => {
    const response = await axios.post(CONFIRMATIONS, confirmation)
    await dispatch(getTodo())
    await dispatch(getChecked())
    await dispatch(getConfirmed())
    return response.data
})

export const updateConnfirmation = createAsyncThunk('appTodo/updateConfirmation', async (confirmation, { dispatch }) => {
    const response = await axios.put(CONFIRMATIONS.concat(`?id_c=${confirmation.id_c}`), confirmation)
    await dispatch(getTodo())
    await dispatch(getChecked())
    await dispatch(getConfirmed())
    return response.data
})

export const confirm = createAsyncThunk('appTodo/updateConfirmation', async (confirmation, { dispatch }) => {
    const response = await axios.put(CONFIRMATIONS.concat(`status/?id_c=${confirmation.id_c}`), confirmation.checked)
    await dispatch(getTodo())
    await dispatch(getChecked())
    await dispatch(getConfirmed())
    return response.data
})

export const deleteConfirmation = createAsyncThunk('appTodo/deleteConfirmation', async (confirmation, { dispatch }) => {
    const response = await axios.delete(CONFIRMATIONS.concat(`?id_c=${confirmation.id_c}`))
    await dispatch(getTodo())
    await dispatch(getChecked())
    await dispatch(getConfirmed())
    return response.data
})

export const todoSlice = createSlice({
    name: 'appTodo',
    initialState: {
        data: [],
        checked: [],
        confirmed: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTodo.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getChecked.fulfilled, (state, action) => {
            state.checked = action.payload.data
        })
        builder.addCase(getConfirmed.fulfilled, (state, action) => {
            state.confirmed = action.payload.data
        })
    }
})

export default todoSlice.reducer