import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_COMMISSIONS_BLOCKS } from '../../../../../../../utility/SourceAPI'

export const getBlocks = createAsyncThunk('appBlocks/getBlocks', async () => {
    const response = await axios.get(CASH_COMMISSIONS_BLOCKS)
    return {
        data: response.data
    }
})

export const addBlock = createAsyncThunk('appBlocks/addBlocks', async (block, { dispatch }) => {
    const response = await axios.post(CASH_COMMISSIONS_BLOCKS, block)
    await dispatch(getBlocks())
    return response.data
})

export const editBlock = createAsyncThunk('appBlocks/editBlocks', async (block, { dispatch }) => {
    const response = await axios.put(CASH_COMMISSIONS_BLOCKS.concat(`${block.id}`), block)
    await dispatch(getBlocks())
    return response.data
})

export const deleteBlock = createAsyncThunk('appBlocks/deleteBlocks', async (id, { dispatch }) => {
    const response = await axios.delete(CASH_COMMISSIONS_BLOCKS.concat(`${id}`))
    await dispatch(getBlocks())
    return response.data
})

export const blocksSlice = createSlice({
    name: 'appBlocks',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBlocks.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default blocksSlice.reducer