import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import {CATALOGS_USERS} from "../../../../../../utility/SourceAPI"


export const getUsersExpense = createAsyncThunk('appUserExpense/getUsersExpense', async () => {
    const response = await axios.get(CATALOGS_USERS.concat('?is_expense=true'))
    return {
        data: response.data
    }
})   
export const getUsersCredit = createAsyncThunk('appUserCredit/getUsersCredit', async () => {
    const response = await axios.get(CATALOGS_USERS.concat('?is_credit=true'))
    return {
        data: response.data
    }
})   
export const getUsersCashBox = createAsyncThunk('appUserCashBox/getUsersCashBox', async () => {
    const response = await axios.get(CATALOGS_USERS.concat('?is_cash_box=true'))
    return {
        data: response.data
    }
})   

export const addCatalogsUsers = createAsyncThunk('appUserExpense/addUsers', async (data) => {
    const response = await axios.post(CATALOGS_USERS, data)
    return {
        data: response.data
    }
})

export const updateCatalogsUsers = createAsyncThunk('appUserExpense/updatesUsers', async (data) => {
    const response = await axios.put(CATALOGS_USERS.concat(data.id), data)
    return {
        data: response.data
    }
})

export const catalogsUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        userExpense: [],
        userCredit : [],
        usersCashBox: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUsersExpense.fulfilled, (state, action) => {
            state.userExpense = action.payload.data
        })
        builder.addCase(getUsersCredit.fulfilled, (state, action) => {
            state.userCredit = action.payload.data
        })
        builder.addCase(getUsersCashBox.fulfilled, (state, action) => {
            state.usersCashBox = action.payload.data
        })
    }
})

export default catalogsUsersSlice.reducer