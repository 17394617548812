import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH } from "../../../../../../utility/SourceAPI"

import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getCash = createAsyncThunk('appCash/getCash', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${data.delivered !== undefined ? `&is_delivered=${data.delivered}` : ''}`) : CASH.concat(data.delivered !== undefined ? `?is_delivered=${data.delivered}` : ''))
    return {
        data: response.data
    }
})
export const getCashPendings = createAsyncThunk('appCash/getCashPendings', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${`&status=pending`}`) : CASH.concat(`?status=pending`))
    return {
        data: response.data
    }
})

export const getCashPendingsIntermediary = createAsyncThunk('appCash/getCashPendingsIntermediary', async (data) => {
    const response = await axios.get(CASH.concat(`?all_=true&intermediary_id=${data.id}&status=pending`))
    return {
        data: response.data
    }
})

export const getCashDelivered = createAsyncThunk('appCash/getCashDelivered', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${`&status=delivered`}`) : CASH.concat(`?status=delivered`))
    return {
        data: response.data
    }
})
export const getCashArmed = createAsyncThunk('appCash/getCashArmed', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${`&status=armed`}`) : CASH.concat(`?status=armed`))
    return {
        data: response.data
    }
})

export const getCashIntermediary = createAsyncThunk('appCash/getCasIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&intermediary_id=${data.id}`) : CASH.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const updateCash = createAsyncThunk('appCash/updateCash', async (cash, { dispatch }) => {
    const response = await axios.put(cash.registred === true ? CASH.concat(`${cash.id}?registred=${cash.registred}`) : CASH.concat(cash.id), cash)
    await dispatch(getCash())
    return response.data
})

export const updatableCash = createAsyncThunk('appCash/updatableCash', async (cash, { dispatch }) => {
    const response = await axios.put(CASH.concat(`updateable/${cash}`))
    await dispatch(getCash())
    return response.data
})

export const updateCashArmed = createAsyncThunk('appCash/updateCashArmed', async (cash, { dispatch }) => {
    const response = await axios.put(CASH.concat('update-armed/'), cash)
    await dispatch(getCashPendings())
    await dispatch(getCashDelivered())
    await dispatch(getCashArmed())
    return response.data
})

export const deleteCash = createAsyncThunk('appCash/deleteCash', async (cash, { dispatch }) => {
    const response = await axios.delete(CASH.concat(cash))
    await dispatch(getCash())
    return response.data
})

export const updateVoucher = createAsyncThunk('appCash/updateVoucher', async (voucher, { dispatch }) => {
    const id = voucher.get('id')
    const response = await axios.put(CASH.concat(`update-voucher/${id}`), voucher, config)
    await dispatch(getCash())
    return response.data
})

export const cashSlice = createSlice({
    name: 'appCash',
    initialState: {
        data: [],
        pendings: [],
        delivered: [],
        armed: [],
        pendingsIntermediary: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCash.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getCashIntermediary.fulfilled, (state, action) => {
            state.cashIntermediary = action.payload.data
        })
        builder.addCase(getCashPendings.fulfilled, (state, action) => {
            state.pendings = action.payload.data
        })
        builder.addCase(getCashDelivered.fulfilled, (state, action) => {
            state.delivered = action.payload.data
        })
        builder.addCase(getCashArmed.fulfilled, (state, action) => {
            state.armed = action.payload.data
        })
        builder.addCase(getCashPendingsIntermediary.fulfilled, (state, action) => {
            state.pendingsIntermediary = action.payload.data
        })
    }
})

export default cashSlice.reducer