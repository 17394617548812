import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CONCEPT } from '../../../../../utility/SourceAPI'

export const getConcept = createAsyncThunk('appConcept/getConcept', async (data) => {
    const response = await axios.get(CONCEPT.concat(`?${data.is_expense ? `is_expense=${data.is_expense}` : `${data.is_intermediary_income ? `is_intermediary_income=${data.is_intermediary_income}` : ''}` }`))
    return {
        data: response.data
    }
})

export const addConcept = createAsyncThunk('appConcept/addConcept', async (concept, { dispatch }) => {
    const response = await axios.post(CONCEPT, concept)
    await dispatch(getConcept())
    return response.data
})

export const conceptSlice = createSlice({
    name: 'appConcept',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getConcept.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default conceptSlice.reducer