// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CHECKBOOKS_ENDPOINT } from '../../../../../../utility/SourceAPI'

export const getCheckbooks = createAsyncThunk('appCheckbooks/getCheckbooks', async () => {
    const response = await axios.get(CHECKBOOKS_ENDPOINT)
    return {
        data: response.data
    }
})

export const addCheckbook = createAsyncThunk('appCheckbook/addCheckbook', async (checkbook, { dispatch }) => {
    const response = await axios.post(CHECKBOOKS_ENDPOINT, checkbook)
    await dispatch(getCheckbooks())
    return response.data
})

export const updateCheckbook = createAsyncThunk('appCheckbook/updateCheckbook', async (checkbook, { dispatch }) => {
    const response = await axios.put(`${CHECKBOOKS_ENDPOINT}${checkbook.id}`, checkbook)
    await dispatch(getCheckbooks())
    return response.data
})

export const deleteCheckbook = createAsyncThunk('appCheckbook/deleteCheckbook', async (checkbook, { dispatch }) => {
    const response = await axios.delete(`${CHECKBOOKS_ENDPOINT}${checkbook}`)
    await dispatch(getCheckbooks())
    return response.data
})

export const appCheckbooksSlice = createSlice({
    name: 'appCheckbooks',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCheckbooks.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appCheckbooksSlice.reducer