import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios"
import {BANK_TYPES_ENDPOINT} from "../../../../../../utility/SourceAPI"

export const getBankTypes = createAsyncThunk('appBankTypes/getBankTypes', async () => {
    const response = await axios.get(BANK_TYPES_ENDPOINT)
    return {
        data: response.data
    }
})

export const appBankTypesSlice = createSlice({
        name: 'appBankTypes',
        initialState: {
            data: []
        },
        reducers: {},
        extraReducers: builder => {
            builder.addCase(getBankTypes.fulfilled, (state, action) => {
                state.data = action.payload.data
            }
            )
        }
        
    }
)

export default appBankTypesSlice.reducer