import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { INTERMEDIARY_INCOME_ENDPOINT } from '../../../../../../../utility/SourceAPI'
import {
    formattedDatePicker,
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds
} from '../../../../../../../utility/formats/formattedDate'

export const getIntermediaryIncome = createAsyncThunk('appIntermediaryIncome/getIntermediaryIncome', async (data) => {  
    const response = await axios.get(data.rangeDates !== undefined ? INTERMEDIARY_INCOME_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&intermediary_id=${data.id}`) : INTERMEDIARY_INCOME_ENDPOINT.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const addIntermediaryIncome = createAsyncThunk('appIntermediaryIncome/addIntermediaryIncome', async (intermediaryIncome, { dispatch }) => {
    const response = await axios.post(INTERMEDIARY_INCOME_ENDPOINT, intermediaryIncome)
    await dispatch(getIntermediaryIncome())
    return response.data
})

export const deleteIntermediaryIncome = createAsyncThunk('appIntermediaryIncome/deleteIntermediaryIncome', async (intermediaryIncome, { dispatch }) => {
    const response = await axios.delete(INTERMEDIARY_INCOME_ENDPOINT.concat(intermediaryIncome))
    await dispatch(getIntermediaryIncome())
    return response.data
})

export const intermediaryIncomeSlice = createSlice({
    name: 'appIntermediaryIncome',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getIntermediaryIncome.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default intermediaryIncomeSlice.reducer