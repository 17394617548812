// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {ADDRESS_ENDPOINT, UPDATE_ADDRESS} from "../../../../../../utility/SourceAPI"

export const getAddress = createAsyncThunk('appAddress/getAddress', async () => {
    const response = await axios.get(ADDRESS_ENDPOINT)
    return {
        data: response.data
    }
})

export const addAddress = createAsyncThunk('appAddress/addAddress', async (address, { dispatch }) => {
    const response = await axios.post(ADDRESS_ENDPOINT,  address)
    await dispatch(getAddress())
    return response.data
})

export const updateAddress = createAsyncThunk('appAddress/updateAddress', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_ADDRESS.concat(data.id),  data.address)
    await dispatch(getAddress())
    return response.data
})

export const appAddressSlice = createSlice({
        name: 'appAddress',
        initialState: {
            data: []
        },
        reducers: {},
        extraReducers: builder => {
            builder.addCase(getAddress.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
    }
)

export default appAddressSlice.reducer
