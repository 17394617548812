const dayjs = require('dayjs')
import 'dayjs/locale/es-mx'

const relativeTime = require('dayjs/plugin/relativeTime')
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault("America/Mexico_City")

dayjs.locale('es-mx')

export const isoDate = (date) => {
    const format_date = dayjs.utc(date).toDate()
    const timePart = dayjs(format_date).tz('UTC').format("HH:mm:ss:SSS")
    const datePart = dayjs(format_date).tz('UTC').format("YYYY-MM-DD")
    const dateISO8601 = `${datePart} ${timePart}`
    return dateISO8601
}

export const formatToday = () => {
    const date = new Date()
    return dayjs(date).format("YYYY-MM-DD")
}

export const formattedDate = (date) => {
    const format_date = dayjs.utc(date).toDate()
    const datePart = dayjs(format_date).tz('UTC').format("YYYY-MM-DD")
    return datePart
}
export const formattedDateExtended = (date) => {
    const format_date = new Date(isoDate(date))
    return date ? dayjs(format_date).format("DD MMMM YYYY") : ''
}

export const formattedDatePicker = (date) => {
    const format_date = new Date(date)
    return dayjs(format_date).format("YYYY-MM-DD")
}

export const formattedDateTimePicker = (date) => {
    const format_date = new Date(date)
    return dayjs(format_date).format("YYYY-MM-DD HH:mm:ss")
}

export const relativeDate = (date) => {
    return date ? dayjs.tz(date).fromNow() : 'nunca'
}

export const formattedDateFlatpickrPlus = (date) => {
    const today = new Date()
    const days = 1000 * 60 * 60 * 24 * date
    const plus = today.getTime() + days
    const dateLimit = new Date(plus)
    return dayjs(dateLimit).format("YYYY-MM-DD")
}

export const formattedDateFlatpickrLess = (date) => {
    const today = new Date()
    const days = 1000 * 60 * 60 * 24 * date
    const plus = today.getTime() - days
    const dateLimit = new Date(plus).toISOString().substring(0, 10)
    return dayjs(dateLimit).format("YYYY-MM-DD")
}

export const dateLimiter = (date) => {
    const today = new Date()
    const diference = (today - new Date(date))
    const days = Math.floor(diference / 86400000)// redondea hacia abajo
    return days
}


export const muiOnlyDate = (date) => {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/
    if (dateFormatRegex.test(date)) {
        const tempDate = `${date} 01:00:00`
        const format_date = new Date(tempDate)
        return format_date
    }
}

export const hours = new Date().getHours()
export const minutes = new Date().getMinutes()
export const seconds = new Date().getSeconds()
export const miliseconds = new Date().getMilliseconds()