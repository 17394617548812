// ** Reducers Imports
import layout from './layout'
import navbar from './navbar'
import auth from './authentication'
import companies from '../views/pages/apps/companies/store'
import companiesStatus from '../views/pages/apps/companies/status/store/index'
import address from "../views/pages/apps/catalogs/address/store"
import partners from "../views/pages/apps/catalogs/partners/store"
import giros from "../views/pages/apps/catalogs/giros/store"
import activities from "../views/pages/apps/catalogs/business-activities/store"
import users from "../views/pages/apps/catalogs/users/store"
import roles from "../views/pages/apps/catalogs/roles/store"
import abilities from "../views/pages/apps/catalogs/abilities/store"
import bankAccounts from "../views/pages/apps/catalogs/bankAccounts/store"
import bankTransactions from "../views/pages/apps/banks/accounts/store"
import bankActivities from "../views/pages/apps/banks/transfers/store"
import bankTypes from "../views/pages/apps/catalogs/bankTypes/store"
import intermediaries from "../views/pages/apps/register/intermediarys/store"
import intermediaryIncome from "../views/pages/apps/register/intermediarys/income/store"
import credit from "../views/pages/apps/register/intermediarys/credits/store"
import devolution from "../views/pages/apps/register/returns/store"
import checks from '../views/pages/apps/gestion/checks/store'
import checkbooks from "../views/pages/apps/gestion/checkbooks/store"
import bankChecks from "../views/pages/apps/banks/checks/bankChecks/store"
import cash from "../views/pages/apps/cash/activities/store/index"
import expenses from "../views/pages/apps/register/intermediarys/bills/store/index"
import cashDeposit from "../views/pages/apps/cash/deposit/store/index"
import invoices from "../views/pages/apps/invoice/invoices/store/index"
import commissions from "../views/pages/apps/register/intermediarys/commissions/store/index"
import clients from "../views/pages/apps/invoice/clients/store/index"
import todo from "../views/pages/apps/todo/store/index"
import checksRegister from "../views/pages/apps/register/checks/store/index"
import cashExpenses from '../views/pages/apps/cash/expenses/store/index'
import locations from '../views/pages/apps/cash/expenses/locations/store'
import transferenceIntermediary from '../views/pages/apps/register/intermediarys/transfer/store'
import cashCommission from "../views/pages/apps/cash/comissions/store"
import concept from "../views/pages/apps/concept/store"
import catalogUsers from "../views/pages/apps/cash/user/store"
import cashBox from "../views/pages/apps/box/store"
import expensesPending from "../views/pages/apps/cash/expenses/pendingExpenses/store"
import cashCredit from '../views/pages/apps/cash/credits/store'
import blocks from '../views/pages/apps/cash/comissions/blocks/store'
import bodyBlocks from '../views/pages/apps/cash/comissions/blocks/bodyBlock/store'
import expensesBlock from '../views/pages/apps/cash/expenses/Blocks/store'

const rootReducer = { navbar, layout, auth, companies, companiesStatus, address, partners, giros, activities, users, roles, abilities, bankAccounts, bankTransactions, bankActivities, bankTypes, intermediaries, credit, devolution, checks, checkbooks, bankChecks, cash, expenses, cashDeposit, commissions, invoices, clients, todo, checksRegister, cashExpenses, locations, transferenceIntermediary, cashCommission, intermediaryIncome, concept, catalogUsers, cashBox, expensesPending, cashCredit, blocks, bodyBlocks, expensesBlock }

export default rootReducer
