import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { COMMISSIONS } from "../../../../../../utility/SourceAPI"
import {
    formattedDatePicker,
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds
} from '../../../../../../utility/formats/formattedDate'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getCashCommission = createAsyncThunk('appCashCommission/getCashCommission', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? COMMISSIONS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : COMMISSIONS)
    return {
        data: response.data
    }
})

export const getCommissionPending = createAsyncThunk('appCashCommission/getCommissionPending', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? COMMISSIONS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&status=pending`) : COMMISSIONS.concat('?status=pending'))
    return {
        data: response.data
    }
})

export const getCommissionArmed = createAsyncThunk('appCashCommission/getCommissionArmed', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? COMMISSIONS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&status=armed`) : COMMISSIONS.concat('?status=armed'))
    return {
        data: response.data
    }
})

export const getCommissionDelivered = createAsyncThunk('appCashCommission/getCommissionDelivered', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? COMMISSIONS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&status=delivered`) : COMMISSIONS.concat('?status=delivered'))
    return {
        data: response.data
    }
})

export const getCommissionBank = createAsyncThunk('appCashCommission/getCommissionBank', async () => {
    const response = await axios.get(COMMISSIONS.concat('?transference=true'))
    return {
        data: response.data
    }
})

export const addCashCommission = createAsyncThunk('appCashCommission/addCashCommission', async (commission, { dispatch }) => {
    const response = await axios.post(COMMISSIONS.concat(`?id_expense_to_block=${commission.expense_id}`), commission)
    await dispatch(getCashCommission())
    return {
        data: response.data
    }
})

export const updateCommission = createAsyncThunk('appCashCommission/updateCommission', async (commission, { dispatch }) => {
    const response = await axios.put(COMMISSIONS.concat(commission.id), commission)
    await dispatch(getCashCommission())
    return response.data
})

export const updateCommissionStatus = createAsyncThunk('appCashCommission/updateCommissionStatus', async (commission) => {
    const response = await axios.put(COMMISSIONS.concat('status'), commission)
    return response.data
})

export const deleteCommission = createAsyncThunk('appCashCommission/deleteCommission', async (commission, { dispatch }) => {
    const response = await axios.delete(COMMISSIONS.concat(commission))
    await dispatch(getCashCommission())
    return response.data
})

export const armDisarmCommission = createAsyncThunk('appCashCommission/armDisarmCommission', async (commission) => {
    const response = await axios.put(COMMISSIONS.concat('update-disarm/'), commission)
    return response.data
})

export const uploadVoucherCommission = createAsyncThunk('appCashCommission/uploadVoucher', async (data) => {
    const id = data.get('id')
    const response = await axios.put(COMMISSIONS.concat(`update_voucher/${id}`), data, config)
    return response.data
})

export const appCashCommissionSlice = createSlice({
    name: 'appCashCommission',
    initialState: {
        data: [],
        commissionArmed: [],
        commissionPending: [],
        commissionDelivered: [],
        commissionBank: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashCommission.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getCommissionPending.fulfilled, (state, action) => {
            state.commissionPending = action.payload.data
        })
        builder.addCase(getCommissionArmed.fulfilled, (state, action) => {
            state.commissionArmed = action.payload.data
        })
        builder.addCase(getCommissionDelivered.fulfilled, (state, action) => {
            state.commissionDelivered = action.payload.data
        })
        builder.addCase(getCommissionBank.fulfilled, (state, action) => {
            state.commissionBank = action.payload.data
        })
    }
})

export default appCashCommissionSlice.reducer