import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_CREDITS } from "../../../../../../utility/SourceAPI"

import {
    formattedDatePicker,
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds
} from '../../../../../../utility/formats/formattedDate'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getCashCredits = createAsyncThunk('appCashCredits/getCashCredits', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_CREDITS.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&user_activity=${data.user_id}`) : CASH_CREDITS.concat(`?user_activity=${data.user_id}`))
    return {
        data: response.data
    }
})

export const addCashCredit = createAsyncThunk('appCashCredits/addCashCredit', async (cashCredit, { dispatch }) => {
    const response = await axios.post(CASH_CREDITS, cashCredit)
    await dispatch(getCashCredits())
    return response.data
})

export const updateCashCredit = createAsyncThunk('appCashCredits/updateCashCredit', async (cashCredit, { dispatch }) => {
    const response = await axios.put(CASH_CREDITS.concat(`${cashCredit.id}`), cashCredit)
    await dispatch(getCashCredits())
    return response.data
})

export const deleteCashCredit = createAsyncThunk('appCashCredits/deleteCashCredit', async (cashCredit, { dispatch }) => {
    await axios.delete(CASH_CREDITS.concat(`${cashCredit}`))
    await dispatch(getCashCredits())
    return cashCredit
})

export const uploadVoucherCashCredit = createAsyncThunk('appCashCredits/uploadVoucherCashCredit', async (data, { dispatch }) => {
    const id = data.get('id')
    const response = await axios.put(CASH_CREDITS.concat(`update_voucher/${id}`), data, config)
    await dispatch(getCashCredits())
    return response.data
})

export const cashCreditsSlice = createSlice({
    name: 'appCashCredits',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashCredits.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default cashCreditsSlice.reducer