// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { BANK_CHECKS_ENDPOINT } from '../../../../../../../utility/SourceAPI'

import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../../utility/formats/formattedDate"

export const getBankChecks = createAsyncThunk('appBankChecks/getBankChecks', async (data) => {
    if (data === undefined) {
        const response = await axios.get(BANK_CHECKS_ENDPOINT)
        return {
            data: response.data
        }
    }
    if (data.rangeDates !== undefined) {
        const response = await axios.get(data.rangeDates !== undefined ? BANK_CHECKS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_CHECKS_ENDPOINT.concat(`?all_=true`))
        return {
            data: response.data
        }
    }
    return data
})

export const getBankChecksTransit = createAsyncThunk('appBankChecksTransit/getBankChecksTransit', async (data) => {
    if (data === undefined) {
        const response = await axios.get(BANK_CHECKS_ENDPOINT.concat('?all_=true&status_=Transito'))
        return {
            data: response.data
        }
    }
    if (data.rangeDates !== undefined) {
        const response = await axios.get(data.rangeDates !== undefined ? BANK_CHECKS_ENDPOINT.concat('?all_=true&status_=Transito').concat(`&end=${formattedDateTimePicker(data.rangeDates.end)}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : BANK_CHECKS_ENDPOINT.concat(`?all_=true&status_=Transito`))
        return {
            data: response.data
        }
    }
    return data
})

export const getBankChecksProvision = createAsyncThunk('appBankChecksProvision/getBankChecksProvision', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_CHECKS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`).concat(`&all_=false&concept=Regreso`).concat(`&intermediary_id=${data.id}`) : BANK_CHECKS_ENDPOINT.concat(`?all_=false&concept=Regreso`).concat(`&intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const getBankCheksTransact = createAsyncThunk('appBankCheksTransact/getBankCheksTransact', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? BANK_CHECKS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`).concat(`&all_=false&concept=Tramite&intermediary_id=${data.id}`) : BANK_CHECKS_ENDPOINT.concat(`?all_=false&concept=Tramite&intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const addBankCheck = createAsyncThunk('appBankChecks/addBankCheck', async (bankCheck, { dispatch }) => {
    const response = await axios.post(BANK_CHECKS_ENDPOINT, bankCheck)
    await dispatch(getBankChecks())
    return response.data
})

export const blankCheck = createAsyncThunk('appBankChecks/blankCheck', async (data, { dispatch }) => {
    const response = await axios.post(BANK_CHECKS_ENDPOINT.concat(`empty/`), data)
    await dispatch(getBankChecks())
    return response.data
})

export const aproveBankCheck = createAsyncThunk('appBankChecks/aproveBankCheck', async (bankCheck, { dispatch }) => {
    const id = parseInt(bankCheck.get("id"))
    const response = await axios.put(BANK_CHECKS_ENDPOINT.concat(`aprove/${id}`), bankCheck)
    await dispatch(getBankChecks())
    return response.data
})

export const updateCheck = createAsyncThunk('appBankChecks/updateCheck', async (bankCheck, { dispatch }) => {
    const response = await axios.put(BANK_CHECKS_ENDPOINT.concat(`${bankCheck.id}`), bankCheck)
    await dispatch(getBankChecks())
    return response.data
})

export const cancelBankCheck = createAsyncThunk('appBankChecks/cancelBankCheck', async (id, { dispatch }) => {
    const response = await axios.put(BANK_CHECKS_ENDPOINT.concat(`cancel/${id}`))
    await dispatch(getBankChecks())
    return response.data
})
export const deleteBankCheck = createAsyncThunk('appBankChecks/deleteBankCheck', async (id, { dispatch }) => {
    const response = await axios.delete(BANK_CHECKS_ENDPOINT.concat(`${id}`))
    await dispatch(getBankChecks())
    return response.data
})

export const appBankChecksSlice = createSlice({
    name: 'appBankChecks',
    initialState: {
        data: [],
        bankChecksTransit: [],
        bankChecksProvision: [],
        bankChecksTransact: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBankChecks.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getBankChecksTransit.fulfilled, (state, action) => {
            state.bankChecksTransit = action.payload.data
        })
        builder.addCase(getBankChecksProvision.fulfilled, (state, action) => {
            state.bankChecksProvision = action.payload.data
        })
        builder.addCase(getBankCheksTransact.fulfilled, (state, action) => {
            state.bankChecksTransact = action.payload.data
        })
    }
})

export default appBankChecksSlice.reducer