// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios"
import { USERS_ENDPOINT, UPDATE_USERS_ENDPOINT, UPDATE_USERS_PASS_ENDPOINT, ASSIGN_CASH_BOX } from "../../../../../../utility/SourceAPI"


export const getUsers = createAsyncThunk('appUsers/getUsers', async () => {
    const response = await axios.get(USERS_ENDPOINT)
    return {
        data: response.data
    }
})
export const getUsersToluca = createAsyncThunk('appUsersToluca/getUsersToluca', async (roleId) => {
    const response = await axios.get(USERS_ENDPOINT.concat(`?role_id=${roleId}`))
    return {
        data: response.data
    }
})
export const getUsersKuspit = createAsyncThunk('appUsersKuspit/getUsersKuspit', async (roleId) => {
    const response = await axios.get(USERS_ENDPOINT.concat(`?role_id=${roleId}`))
    return {
        data: response.data
    }
})

export const addUser = createAsyncThunk('appUsers/addUser', async (user, { dispatch }) => {
    const response = await axios.post(USERS_ENDPOINT,  user)
    await dispatch(getUsers())
    return response.data
})

//Update user
export const updateUser = createAsyncThunk('appUsers/updateUser', async (user, { dispatch }) => {
    const response = await axios.put(UPDATE_USERS_ENDPOINT.concat(user.userId),  user.userData)
    await dispatch(getUsers())
    return response.userData
})

//Update userPassword
export const updateUserPassword = createAsyncThunk('appUsersPassword/updateUserPassword', async (user, { dispatch }) => {
    const response = await axios.put(UPDATE_USERS_PASS_ENDPOINT.concat(user.userId),  user.userData)
    await dispatch(getUsers())
    return response.userData
})

//Assign CashBox
export const assignCashBox = createAsyncThunk('appUsers/assignCashBox', async (user, { dispatch }) => {
    const response = await axios.put(ASSIGN_CASH_BOX.concat(user.id),  user)
    await dispatch(getUsers())
    return response.userData
})

export const getUserById = createAsyncThunk('appUsers/getUserById', async (userId) => {
    const response = await axios.get(USERS_ENDPOINT.concat(`?id=${userId}`))
    return response.data
})

export const appUsersSlice = createSlice({
    name: 'appUsers',
    initialState: {
        data: [],
        user: {},
        usersToluca: [],
        usersKuspit: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getUsers.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getUsersToluca.fulfilled, (state, action) => {
            state.usersToluca = action.payload.data
        })
        builder.addCase(getUsersKuspit.fulfilled, (state, action) => {
            state.usersKuspit = action.payload.data
        })
        builder.addCase(getUserById.fulfilled, (state, action) => {
            state.user = action.payload
        })
    }
})

export default appUsersSlice.reducer