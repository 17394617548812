import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import {TRANSFER_INTERMEDIARY_ENDPOINT} from "../../../../../../../utility/SourceAPI"
import {
    formattedDatePicker, 
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds
} from '../../../../../../../utility/formats/formattedDate'

export const getTranferenceIntermediary = createAsyncThunk('appTranferenceIntermediary/getTranferenceIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? TRANSFER_INTERMEDIARY_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&transmitter_by_id=${data.id}&receiver_by_id=${data.id}`) : TRANSFER_INTERMEDIARY_ENDPOINT.concat(`?transmitter_by_id=${data.id}&receiver_by_id=${data.id}`))
    return {
        data: response.data
    }
})

export const addTranferenceIntermediary = createAsyncThunk('appTranferenceIntermediary/addTranferenceIntermediary', async (data, { dispatch }) => {
    const response = await axios.post(TRANSFER_INTERMEDIARY_ENDPOINT, data)
    await dispatch(getTranferenceIntermediary())
    return {
        data: response.data
    }
})

export const updateTranferenceIntermediary = createAsyncThunk('appTranferenceIntermediary/updateTranferenceIntermediary', async (data, { dispatch }) => {
    const response = await axios.put(TRANSFER_INTERMEDIARY_ENDPOINT.concat(`${data.id}`), data)
    await dispatch(getTranferenceIntermediary())
    return {
        data: response.data
    }
})

export const deleteTranferenceIntermediary = createAsyncThunk('appTranferenceIntermediary/deleteTranferenceIntermediary', async (data, { dispatch }) => {
    const response = await axios.delete(TRANSFER_INTERMEDIARY_ENDPOINT.concat(`${data.id}`))
    await dispatch(getTranferenceIntermediary())
    return {
        data: response.data
    }
})

export const transferenceIntermediarySlice = createSlice({
    name: 'appTranferenceIntermediary',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getTranferenceIntermediary.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default transferenceIntermediarySlice.reducer