// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {GIROS_ENDPOINT, UPDATE_GIRO} from "../../../../../../utility/SourceAPI"

export const getGiros = createAsyncThunk('appGiros/getGiros', async () => {
    const response = await axios.get(GIROS_ENDPOINT)
    return {
        data: response.data
    }
})

export const addGiro = createAsyncThunk('appGiros/addGiro', async (giro, { dispatch }) => {
    const response = await axios.post(GIROS_ENDPOINT,  giro)
    await dispatch(getGiros())
    return response.data
})

export const updateGiro = createAsyncThunk('appGiros/updateGiro', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_GIRO.concat(data.id),  data.giro)
    await dispatch(getGiros())
    return response.data
})

export const appGirosSlice = createSlice({
        name: 'appGiros',
        initialState: {
            data: []
        },
        reducers: {},
        extraReducers: builder => {
            builder.addCase(getGiros.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
    }
)

export default appGirosSlice.reducer
