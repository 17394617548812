// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {PARTNERS_ENDPOINT, UPDATE_PARTNER} from "../../../../../../utility/SourceAPI"

export const getPartners = createAsyncThunk('appPartners/getPartners', async () => {
    const response = await axios.get(PARTNERS_ENDPOINT)
    return {
        data: response.data
    }
})

export const addPartner = createAsyncThunk('appPartners/addPartner', async (partner, { dispatch }) => {
    const response = await axios.post(PARTNERS_ENDPOINT,  partner)
    await dispatch(getPartners())
    return response.data
})

export const updatePartner = createAsyncThunk('appPartners/updatePartner', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_PARTNER.concat(data.id),  data.partner)
    await dispatch(getPartners())
    return response.data
})

export const appPartnersSlice = createSlice({
        name: 'appPartners',
        initialState: {
            data: []
        },
        reducers: {},
        extraReducers: builder => {
            builder.addCase(getPartners.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
    }
)

export default appPartnersSlice.reducer
