import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_DEPOSIT } from "../../../../../../utility/SourceAPI"

import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"

export const getCashDeposit = createAsyncThunk('appCashDeposit/getCashDeposit', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_DEPOSIT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : CASH_DEPOSIT)
    return {
        data: response.data
    }
})

export const getCashDepositIntermediary = createAsyncThunk('appCashDepositIntermediary/getCashDepositIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_DEPOSIT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 5}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&intermediary_id=${data.id}`) : CASH_DEPOSIT.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const addCashDeposit = createAsyncThunk('appCashDeposit/addCashDeposit', async (cashDeposit, { dispatch }) => {
    const response = await axios.post(CASH_DEPOSIT, cashDeposit)
    await dispatch(getCashDeposit())
    return response.data
})

export const updateCashDeposit = createAsyncThunk('appCashDeposit/updateCashDeposit', async (cashDeposit, { dispatch }) => {
    const response = await axios.put(CASH_DEPOSIT.concat(`${cashDeposit.id}`), cashDeposit)
    await dispatch(getCashDeposit())
    return response.data
})

export const deleteCashDeposit = createAsyncThunk('appCashDeposit/deleteCashDeposit', async (id, { dispatch }) => {
    const response = await axios.delete(CASH_DEPOSIT.concat(`${id}`))
    await dispatch(getCashDeposit())
    return response.data
})

export const appCashDepositSlice = createSlice({
    name: 'appCashDeposit',
    initialState: {
        data: [],
        cashDepositIntermediary: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashDeposit.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getCashDepositIntermediary.fulfilled, (state, action) => {
            state.cashDepositIntermediary = action.payload.data
        })
    }
})

export default appCashDepositSlice.reducer