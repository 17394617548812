import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { LOCATIONS } from '../../../../../../../utility/SourceAPI'


export const getLocations = createAsyncThunk('appLocations/getLocations', async () => {
    const response = await axios.get(LOCATIONS)
    return {
        data: response.data
    }
})

export const addLocation = createAsyncThunk('appLocations/addLocation', async (location, { dispatch }) => {
    const response = await axios.post(LOCATIONS, location)
    await dispatch(getLocations())
    return {
        data: response.data
    }
})

export const updateLocation = createAsyncThunk('appLocations/updateLocation', async (location, { dispatch }) => {
    const response = await axios.put(LOCATIONS.concat(location.id), location)
    await dispatch(getLocations())
    return {
        data: response.data
    }
})

export const transferCashFlow = createAsyncThunk('appLocations/transferCashFlow', async (location, { dispatch }) => {
    const response = await axios.post(LOCATIONS.concat(`transference/?id_trasmitter=${location.sender}&id_receiver=${location.receiver}`), location)
    await dispatch(getLocations())
    return {
        data: response.data
    }
})

export const deleteCashFLowTransference = createAsyncThunk('appLocations/deleteCashFLowTransference', async (location, { dispatch }) => {
    const response = await axios.delete(LOCATIONS.concat(`transference/${location}`))
    await dispatch(getLocations())
    return {
        data: response.data
    }
})

export const locationsSlice = createSlice({
    name: 'appLocations',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getLocations.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default locationsSlice.reducer