// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { INTERMEDIARIES_ENDPOINT, UPDATE_INTERMEDIARIES_ENDPOINT, SEARCH_INTERMEDIARIES_ENDPOINT } from "../../../../../../utility/SourceAPI"

export const getIntermediaries = createAsyncThunk('appIntermediaries/getIntermediaries', async () => {
    const response = await axios.get(INTERMEDIARIES_ENDPOINT)
    return {
        data: response.data
    }
})

export const getintermediariesResume = createAsyncThunk('appIntermediaries/getIntermediariesResume', async () => {
    const response = await axios.get(INTERMEDIARIES_ENDPOINT.concat('resume'))
    return {
        data: response.data
    }
})

export const getActiveIntermediaries = createAsyncThunk('appActiveIntermediaries/getActiveIntermediaries', async (active) => {
    const response = await axios.get(INTERMEDIARIES_ENDPOINT.concat(`?include_inactive=${active}`))
    return {
        data: response.data
    }
})
export const getIntermediary = createAsyncThunk('appIntermediary/getIntermediary', async (id) => {
    const response = await axios.get(SEARCH_INTERMEDIARIES_ENDPOINT.concat(id))
    return {
        data: response.data
    }
})

export const addIntermediary = createAsyncThunk('appIntermediaries/addIntermediary', async (intermediary, { dispatch }) => {
    const response = await axios.post(INTERMEDIARIES_ENDPOINT, intermediary)
    await dispatch(getIntermediaries())
    return response.data
})

export const updateIntermediary = createAsyncThunk('appIntermediaries/updateIntermediary', async (intermediary, { dispatch }) => {
    const response = await axios.put(UPDATE_INTERMEDIARIES_ENDPOINT.concat(intermediary.id), intermediary)
    await dispatch(getIntermediaries())
    return response.data
})

export const desactivateIntermediary = createAsyncThunk('appIntermediaries/desactivateIntermediary', async (intermediary, { dispatch }) => {
    const {active} = JSON.parse(localStorage.getItem('intermediaryConfig'))
    const response = await axios.put(INTERMEDIARIES_ENDPOINT.concat(`deactivate/${intermediary.id}`))
    await dispatch(getActiveIntermediaries(active))
    return response.data
})


export const appIntermediariesSlice = createSlice({
    name: 'appIntermediaries',
    initialState: {
        data: [],
        activeIntermediaries: [],
        searchIntermediary: [],
        intermediaryResume: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getIntermediaries.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getActiveIntermediaries.fulfilled, (state, action) => {
            state.activeIntermediaries = action.payload.data
        })
        builder.addCase(getIntermediary.fulfilled, (state, action) => {
            state.searchIntermediary = action.payload.data
        })
        builder.addCase(getintermediariesResume.fulfilled, (state, action) => {
            state.intermediaryResume = action.payload.data
        })
    }
})

export default appIntermediariesSlice.reducer