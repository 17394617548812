// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CREDITS_ENDPOINT } from "../../../../../../../utility/SourceAPI"
import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../../utility/formats/formattedDate"

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}
export const getCredit = createAsyncThunk('appCredit/getCredit', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CREDITS_ENDPOINT.concat(`?intermediary_id=${data.id}`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : CREDITS_ENDPOINT.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const newCredit = createAsyncThunk('appCredit/addCredit', async (credit, { dispatch }) => {
    const id = credit.get('intermediary_id')
    const data = {
        id
    }
    const response = await axios.post(CREDITS_ENDPOINT, credit, config)
    await dispatch(getCredit(data))
    return response.data
})

export const deleteCredit = createAsyncThunk('appCredit/deleteCredit', async (id, { dispatch }) => {
    const response = await axios.delete(CREDITS_ENDPOINT.concat(`${id}`))
    await dispatch(getCredit())
    return response.data
})

export const appCreditSlice = createSlice({
    name: 'appCredit',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCredit.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appCreditSlice.reducer