// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import {
    formattedDatePicker, formattedDateTimePicker, minutes,
    seconds,
    miliseconds
} from '../../../../../../../utility/formats/formattedDate'
import { COMMISSIONS_ENDPOINT } from '../../../../../../../utility/SourceAPI'

export const getCommissions = createAsyncThunk('appCommissions/getCommissions', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? COMMISSIONS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&intermediary_id=${data.id}`) : COMMISSIONS_ENDPOINT.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})

export const newCommission = createAsyncThunk('appCommissions/addCommission', async (data, { dispatch }) => {
    const response = await axios.post(COMMISSIONS_ENDPOINT.concat('take'), data.commission)
    await dispatch(getCommissions(data))
    return response.data
})

export const addCommission = createAsyncThunk('appCommissions/addCommission', async (data, { dispatch }) => {
    const response = await axios.post(COMMISSIONS_ENDPOINT.concat('add'), data)
    await dispatch(getCommissions(data))
    return response.data
})

export const appCommissionsSlice = createSlice({
    name: 'appCommissions',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCommissions.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appCommissionsSlice.reducer