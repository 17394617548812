// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {ROLES_ENDPOINT} from "../../../../../../utility/SourceAPI"

export const getRoles = createAsyncThunk('appRoles/getRoles', async () => {
    const response = await axios.get(ROLES_ENDPOINT)
    return {
        data: response.data
    }
})

export const addRole = createAsyncThunk('appRoles/addRoles', async (role, { dispatch }) => {
    const response = await axios.post(ROLES_ENDPOINT, role)
    await dispatch(getRoles())
    return response.data
})

export const appRolesSlice = createSlice({
    name: 'appRoles',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getRoles.fulfilled, (state, action) => {
            state.data = action.payload.data
        }
        )
    }
})


export default appRolesSlice.reducer