// ** Auth Endpoints
import {LOGIN_ENDPOINT} from "../../../utility/SourceAPI"

export default {
  loginEndpoint: LOGIN_ENDPOINT,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'access_token'
  // storageRefreshTokenKeyName: 'refreshToken'
}
