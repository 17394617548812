import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_EXPENSES } from "../../../../../../utility/SourceAPI"
import {
    formattedDatePicker,
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds
} from '../../../../../../utility/formats/formattedDate'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getCashExpenses = createAsyncThunk('appCashExpenses/getCashExpenses', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_EXPENSES.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}${data.location_id !== undefined ? `&location_id=${data.location_id}` : ''}`) : CASH_EXPENSES.concat(`${data.location_id !== undefined ? `?location_id=${data.location_id}` : ''}`))
    return {
        data: response.data
    }
})

// export const getCashCredits = createAsyncThunk('appCashExpenses/getCashCredits', async (data) => {
//     const response = await axios.get(data.rangeDates !== undefined ? CASH_EXPENSES.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}&is_credit=true&user_activity=${data.user_id}`) : CASH_EXPENSES.concat(`?is_credit=true&user_activity=${data.user_id}`))
//     return {
//         data: response.data
//     }
// })

export const getAllCashExpenses = createAsyncThunk('appCashExpenses/getAllCashExpenses', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? CASH_EXPENSES.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : CASH_EXPENSES)
    return {
        data: response.data
    }
})

export const addCashExpense = createAsyncThunk('appCashExpenses/addCashExpense', async (cashExpense, { dispatch }) => {
    const response = await axios.post(cashExpense.is_out !== undefined ? CASH_EXPENSES.concat(`?is_out=${cashExpense.is_out}`) : CASH_EXPENSES, cashExpense)
    await dispatch(getCashExpenses())
    return response.data
})

export const addCommission = createAsyncThunk('appCashExpenses/addCommission', async (cashExpense, { dispatch }) => {
    const response = await axios.post(CASH_EXPENSES.concat('commission'), cashExpense)
    await dispatch(getCashExpenses())
    return response.data
})

export const updateCashExpense = createAsyncThunk('appCashExpenses/updateCashExpense', async (cashExpense, { dispatch }) => {
    const response = await axios.put(CASH_EXPENSES.concat(cashExpense.id), cashExpense)
    await dispatch(getCashExpenses())
    return response.data
})

export const deleteCashExpense = createAsyncThunk('appCashExpenses/deleteCashExpense', async (cashExpense, { dispatch }) => {
    const response = await axios.delete(CASH_EXPENSES.concat(cashExpense))
    await dispatch(getCashExpenses())
    return response.data
})

export const addVoucher = createAsyncThunk('appCashExpenses/addVoucher', async (cashExpense, { dispatch }) => {
    const id = cashExpense.get('id')
    const response = await axios.put(CASH_EXPENSES.concat(`update_voucher/${id}`), cashExpense, config)
    await dispatch(getCashExpenses())
    return response.data
})

export const cashExpensesSlice = createSlice({
    name: 'appCashExpenses',
    initialState: {
        data: [],
        allCashExpenses: [],
        dataConcepts: []
        // cashCredits: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashExpenses.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getAllCashExpenses.fulfilled, (state, action) => {
            state.allCashExpenses = action.payload.data
        })
        // builder.addCase(getCashCredits.fulfilled, (state, action) => {
        //     state.cashCredits = action.payload.data
        // })
    }
})

export default cashExpensesSlice.reducer