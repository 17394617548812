// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { COMPANIES_STATUS } from '../../../../../../utility/SourceAPI'

export const getCompaniesStatus = createAsyncThunk('appCompaniesStatus/getCompaniesStatus', async () => {
    const response = await axios.get(COMPANIES_STATUS)

    return {
        data: response.data
    }
})

export const addCompanyStatus = createAsyncThunk('appCompaniesStatus/addCompanyStatus', async (company, { dispatch }) => {
    const response = await axios.post(COMPANIES_STATUS, company)
    await dispatch(getCompaniesStatus())
    return {
        data: response.data
    }
})

export const appCompaniesStatusSlice = createSlice({
    name: 'appCompaniesStatus',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCompaniesStatus.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appCompaniesStatusSlice.reducer