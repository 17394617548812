// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { REGISTER_CHECKS_ENDPOINT } from '../../../../../../utility/SourceAPI'

import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"


//Bank Checks Register
export const getBankCheksRegister = createAsyncThunk('appBankCheksRegister/getBankCheksRegister', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? REGISTER_CHECKS_ENDPOINT.concat(`?end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`).concat(data.id !== undefined ? `&intermediary_id=${data.id}` : '') : REGISTER_CHECKS_ENDPOINT.concat(data.id !== undefined ? `?intermediary_id=${data.id}` : ''))
    return {
        data: response.data
    }
})

export const getBankCheksRegisterPending = createAsyncThunk('appBankCheksRegister/getBankCheksRegisterPending', async () => {
    const response = await axios.get(REGISTER_CHECKS_ENDPOINT.concat('?status=pending'))
    return {
        data: response.data
    }
})

export const getBankCheksRegisterUpdatable = createAsyncThunk('appBankCheksRegister/getBankCheksRegisterUpdatable', async () => {
    const response = await axios.get(REGISTER_CHECKS_ENDPOINT.concat('?status=updateable'))
    return {
        data: response.data
    }
})

export const getBankCheksRegisterCancelable = createAsyncThunk('appBankCheksRegister/getBankCheksRegisterCancelable', async () => {
    const response = await axios.get(REGISTER_CHECKS_ENDPOINT.concat('?status=cancelable'))
    return {
        data: response.data
    }
})

export const getBankCheksRegisterDeletable = createAsyncThunk('appBankCheksRegister/getBankCheksRegisterDeletable', async () => {
    const response = await axios.get(REGISTER_CHECKS_ENDPOINT.concat('?status=deleteable'))
    return {
        data: response.data
    }
})

export const getBankCheksBox = createAsyncThunk('appBankCheksRegister/getBankCheksBox', async () => {
    const response = await axios.get(REGISTER_CHECKS_ENDPOINT.concat('?status=transit&control_number=CHEQUE DE CAJA'))
    return {
        data: response.data
    }
})

export const requestUpdateCheckRegister = createAsyncThunk('appUpdateCheckRegister/requestUpdateCheckRegister', async (data) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`is_updateable/${data}`))
    return {
        data: response.data
    }
})

export const updateCheckRegister = createAsyncThunk('appUpdateCheckRegister/updateCheckRegister', async (bankCheck, { dispatch }) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`${bankCheck.id}`), bankCheck)
    await dispatch(getBankCheksRegister())
    return response.data
})

export const requestDeleteChecks = createAsyncThunk('appDeleteChecks/requestDeleteChecks', async (data) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`is_deleted/${data}`))
    return response.data
})

export const deleteCheckRegister = createAsyncThunk('appDeleteCheckRegister/deleteCheckRegister', async (id, { dispatch }) => {
    const response = await axios.delete(REGISTER_CHECKS_ENDPOINT.concat(`${id}`))
    await dispatch(getBankCheksRegister())
    return response.data
})

export const cancelCheckRegister = createAsyncThunk('appCancelCheckRegister/cancelCheckRegister', async (id, { dispatch }) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`cancel-check/${id}`))
    await dispatch(getBankCheksRegister())
    return response.data
})

export const requestCancelCheck = createAsyncThunk('appCancelCheck/requestCancelCheck', async (data) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`is_cancelable/${data}`))
    return response.data
})

export const authorizeUpdateCheck = createAsyncThunk('appAuthorizeUpdateCheck/authorizeUpdateCheck', async (data) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`updateable_authorized/${data}`))
    return response.data
})

export const authorizeCancelCheck = createAsyncThunk('appAuthorizeUpdateCheck/authorizeCancelCheck', async (data) => {
    const response = await axios.put(REGISTER_CHECKS_ENDPOINT.concat(`cancel-check/${data}`))
    return response.data
})

export const authorizeDeleteCheck = createAsyncThunk('appAuthorizeUpdateCheck/authorizeDeleteCheck', async (data) => {
    const response = await axios.delete(REGISTER_CHECKS_ENDPOINT.concat(`${data}`))
    return response.data
})

export const appRegisterChecksSlice = createSlice({
    name: 'appRegisterChecks',
    initialState: {
        data: [],
        dataPending: [],
        dataUpdatable: [],
        dataCancelable: [],
        dataDeletable: [],
        dataBox: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBankCheksRegister.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getBankCheksRegisterPending.fulfilled, (state, action) => {
            state.dataPending = action.payload.data
        })
        builder.addCase(getBankCheksRegisterUpdatable.fulfilled, (state, action) => {
            state.dataUpdatable = action.payload.data
        })
        builder.addCase(getBankCheksRegisterCancelable.fulfilled, (state, action) => {
            state.dataCancelable = action.payload.data
        })
        builder.addCase(getBankCheksRegisterDeletable.fulfilled, (state, action) => {
            state.dataDeletable = action.payload.data
        })
        builder.addCase(getBankCheksBox.fulfilled, (state, action) => {
            state.dataBox = action.payload.data
        })
    }
})

export default appRegisterChecksSlice.reducer