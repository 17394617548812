// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {BUSINESS_ACTIVITIES_ENDPOINT, UPDATE_ACTIVITY} from "../../../../../../utility/SourceAPI"

export const getActivities = createAsyncThunk('appActivities/getActivities', async () => {
    const response = await axios.get(BUSINESS_ACTIVITIES_ENDPOINT)
    return {
        data: response.data
    }
})

export const addActivity = createAsyncThunk('appActivities/addActivity', async (activity, { dispatch }) => {
    const response = await axios.post(BUSINESS_ACTIVITIES_ENDPOINT,  activity)
    await dispatch(getActivities())
    return response.data
})

export const updateActivity = createAsyncThunk('appActivities/updateActivity', async (data, { dispatch }) => {
    const response = await axios.put(UPDATE_ACTIVITY.concat(data.id),  data.activity)
    await dispatch(getActivities())
    return response.data
})

export const appActivitiesSlice = createSlice({
        name: 'appActivities',
        initialState: {
            data: []
        },
        reducers: {},
        extraReducers: builder => {
            builder.addCase(getActivities.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
    }
)

export default appActivitiesSlice.reducer
