import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { EXPENSE_TO_BLOCK } from '../../../../../../../utility/SourceAPI'

export const getExpensesBlocks = createAsyncThunk('appExpensesBlocks/getExpensesBlocks', async () => {
    const response = await axios.get(EXPENSE_TO_BLOCK)
    return {
        data: response.data
    }
})

export const expensesBlocksSlice = createSlice({
    name: 'appExpensesBlocks',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getExpensesBlocks.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default expensesBlocksSlice.reducer