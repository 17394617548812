// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CLIENTS_ENDPOINT } from '../../../../../../utility/SourceAPI'

export const getClients = createAsyncThunk('appClients/getClients', async () => {
    const response = await axios.get(CLIENTS_ENDPOINT)
    return {
        data: response.data
    }
})

export const appClientsSlice = createSlice({
    name: 'appClients',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getClients.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default appClientsSlice.reducer