import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_COMMISSIONS_BLOCKS_BODY } from '../../../../../../../../utility/SourceAPI'


export const getBodyBlocks = createAsyncThunk('appBodyBlocks/getBodyBlocks', async () => {
    const response = await axios.get(CASH_COMMISSIONS_BLOCKS_BODY)
    return {
        data: response.data
    }
})

export const getBoodyBlocksById = createAsyncThunk('appBodyBlocks/getBodyBlocksById', async (id) => {
    const response = await axios.get(CASH_COMMISSIONS_BLOCKS_BODY.concat(`?block_id=${id}`))
    return {
        data: response.data
    }
})

export const getBodyBlockPreview = createAsyncThunk('appBodyBlocks/getBodyBlockPreview', async (id) => {
    const response = await axios.get(CASH_COMMISSIONS_BLOCKS_BODY.concat(`previous?block_id=${id}`))
    return {
        data: response.data
    }
})

export const updateBodyBlock = createAsyncThunk('appBodyBlocks/updateBodyBlock', async (block, { dispatch }) => {
    const response = await axios.put(CASH_COMMISSIONS_BLOCKS_BODY.concat(`${block.id}`), block)
    await dispatch(getBodyBlocks())
    return response.data
})

export const addBodyBlock = createAsyncThunk('appBodyBlocks/addBodyBlock', async (block, { dispatch }) => {
    const response = await axios.post(CASH_COMMISSIONS_BLOCKS_BODY, block)
    await dispatch(getBodyBlocks())
    return response.data
})

export const deleteBodyBlock = createAsyncThunk('appBodyBlocks/deleteBodyBlock', async (id, { dispatch }) => {
    const response = await axios.delete(CASH_COMMISSIONS_BLOCKS_BODY.concat(`${id}`))
    await dispatch(getBodyBlocks())
    return response.data
})

export const bodyBlocksSlice = createSlice({
    name: 'appBodyBlocks',
    initialState: {
        data: [],
        blocks: [],
        dataPreview: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getBodyBlocks.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getBoodyBlocksById.fulfilled, (state, action) => {
            state.blocks = action.payload.data
        })
        builder.addCase(getBodyBlockPreview.fulfilled, (state, action) => {
            state.dataPreview = action.payload.data
        })
    }
})

export default bodyBlocksSlice.reducer