// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {ABILITIES_ENDPOINT} from "../../../../../../utility/SourceAPI"

export const getAbilities = createAsyncThunk('appAbilities/getAbilities', async () => {
  const response = await axios.get(ABILITIES_ENDPOINT)
  return {
    data: response.data
  }
})

export const appAbilitiesSlice = createSlice({
  name: 'appAbilities',
  initialState: {
    data: []
  },
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getAbilities.fulfilled, (state, action) => {
          state.data = action.payload.data
        }
    )
  }
})


export default appAbilitiesSlice.reducer