// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { DEVOLUTIONS } from '../../../../../../utility/SourceAPI'
import {
    formattedDateTimePicker,
    minutes,
    seconds,
    miliseconds,
    formattedDatePicker
} from "../../../../../../utility/formats/formattedDate"

export const getDevolutions = createAsyncThunk('appDevolutions/getDevolutions', async (rangeDates) => {
    const response = await axios.get(rangeDates !== undefined ? DEVOLUTIONS.concat(`?end=${formattedDatePicker(rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(rangeDates.start)}`) : DEVOLUTIONS)
    return {
        data: response.data
    }
})
export const getDevolutionsIntermediary = createAsyncThunk('appDevolutionsIntermediary/getDevolutionsIntermediary', async (data) => {
    const response = await axios.get(data.rangeDates !== undefined ? DEVOLUTIONS.concat(`?intermediary_id=${data.id}`).concat(`&end=${formattedDatePicker(data.rangeDates.end)} 23:${minutes + 1}:${seconds}.${miliseconds}&start=${formattedDateTimePicker(data.rangeDates.start)}`) : DEVOLUTIONS.concat(`?intermediary_id=${data.id}`))
    return {
        data: response.data
    }
})
export const addDevolution = createAsyncThunk('appDevolution/addDevolution', async (devolution, { dispatch }) => {
    const response = await axios.post(DEVOLUTIONS, devolution)
    await dispatch(getDevolutions())
    return response.data
})

export const appDevolutionsSlice = createSlice({
    name: 'appDevolutions',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getDevolutions.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getDevolutionsIntermediary.fulfilled, (state, action) => {
            state.dataDevolutionsIntermediary = action.payload.data
        })
    }
})

export default appDevolutionsSlice.reducer