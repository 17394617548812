// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { formattedDatePicker } from '../../../../../../utility/formats/formattedDate'
import { CFDI_ENDPOINT, CFDI_READ_XML_ENDPOINT } from '../../../../../../utility/SourceAPI'

const config = {
    headers: {
        'Content-Type': 'multipart/form-data;boundary=boundary'
    }
}

export const getInvoice = createAsyncThunk('appInvoice/getInvoice', async (data) => {
    let params = {}
    if (data.rfcEmisor) {
        params = { ...params, emisor_rfc: data.rfcEmisor }
    } else if (data.emisor_rfc.length > 0) {
        params = { ...params, emisor_rfc: data.emisor_rfc }
    }
    if (data.intermediario.length > 0) {
        params = { ...params, intermediario_id: data.intermediario }
    }
    if (data.receptor_rfc.length > 0) {
        params = { ...params, receptor_rfc: data.receptor_rfc }
    }
    if (data.status.length > 0) {
        params = { ...params, status: data.status }
    }
    const queryString = Object.keys(params)
        .map(key => {
            const values = Array.isArray(params[key]) ? params[key] : [params[key]]
            return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .join('&')
    const url = `${CFDI_ENDPOINT}${queryString ? `?${queryString}` : ''}`

    const response = data.rangeDates.start !== undefined ? await axios.get(url.concat(`&end=${formattedDatePicker(data.rangeDates.end)}&start=${formattedDatePicker(data.rangeDates.start)}`)) : await axios.get(url)
    return {
        data: response.data
    }
})

export const getInvoiceByIntermediary = createAsyncThunk('appInvoice/getInvoiceByIntermediary', async (data) => {
    let params = {}
    if (data.emisor_rfc.length > 0) {
        params = { ...params, emisor_rfc: data.emisor_rfc }
    }
    if (data.intermediario.length > 0) {
        params = { ...params, intermediario_id: data.intermediario }
    }
    if (data.receptor_rfc.length > 0) {
        params = { ...params, receptor_rfc: data.receptor_rfc }
    }
    if (data.status.length > 0) {
        params = { ...params, status: data.status }
    }
    const queryString = Object.keys(params)
        .map(key => {
            const values = Array.isArray(params[key]) ? params[key] : [params[key]]
            return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .join('&')
    const url = `${CFDI_ENDPOINT}group-intermediario/${data.intermediaryId !== undefined ? `?intermediario_id=${data.intermediaryId}` : ''}${queryString ? `${data.intermediaryId !== undefined ? '&' : '?'}${queryString}` : ''}`
    const response = data.rangeDates.start !== undefined ? await axios.get(url.concat(`${queryString ? '&' : '?'}end=${formattedDatePicker(data.rangeDates.end)}&start=${formattedDatePicker(data.rangeDates.start)}`)) : await axios.get(url)
    return {
        data: response.data
    }
})

export const getInvoiceByCompany = createAsyncThunk('appInvoice/getInvoiceByCompany', async (data) => {
    let params = {}
    if (data.emisor_rfc.length > 0) {
        params = { ...params, emisor_rfc: data.emisor_rfc }
    }
    if (data.intermediario.length > 0) {
        params = { ...params, intermediario_id: data.intermediario }
    }
    if (data.rfcReceptor) {
        params = { ...params, receptor_rfc: data.rfcReceptor }
    } else if (data.receptor_rfc.length > 0) {
        params = { ...params, receptor_rfc: data.receptor_rfc }
    }
    if (data.status.length > 0) {
        params = { ...params, status: data.status }
    }
    const queryString = Object.keys(params)
        .map(key => {
            const values = Array.isArray(params[key]) ? params[key] : [params[key]]
            return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .join('&')
    const url = `${CFDI_ENDPOINT}group-empresa/${queryString ? `?${queryString}` : ''}`
    const response = data.rangeDates.start !== undefined ? await axios.get(url.concat(`${queryString ? '&' : '?'}end=${formattedDatePicker(data.rangeDates.end)}&start=${formattedDatePicker(data.rangeDates.start)}`)) : await axios.get(url)
    return {
        data: response.data
    }
})

export const getInvoiceByClient = createAsyncThunk('appInvoice/getInvoiceByClient', async (data) => {
    let params = {}
    if (data.emisor_rfc.length > 0) {
        params = { ...params, emisor_rfc: data.emisor_rfc }
    }
    if (data.receptor_rfc.length > 0) {
        params = { ...params, receptor_rfc: data.receptor_rfc }
    }
    if (data.intermediary_id) {
        params = { ...params, intermediario_id: data.intermediary_id }
    } else if (data.intermediario.length > 0) {
        params = { ...params, intermediario_id: data.intermediario }
    }
    if (data.status.length > 0) {
        params = { ...params, status: data.status }
    }
    const queryString = Object.keys(params)
        .map(key => {
            const values = Array.isArray(params[key]) ? params[key] : [params[key]]
            return values.map(value => `${key}=${encodeURIComponent(value)}`).join('&')
        })
        .join('&')
    // const url = `${CFDI_ENDPOINT}group-cliente/${data.intermediary_id ? `?intermediario_id=${data.intermediary_id}` : ''}${queryString ? data.intermediary_id ? '&' : '?'`${queryString}` : ''}`
    const url = `${CFDI_ENDPOINT}group-cliente/${queryString ? `?${queryString}` : ''}`
    const response = data.rangeDates.start !== undefined ? await axios.get(url.concat(`${queryString ? '&' : '?'}end=${formattedDatePicker(data.rangeDates.end)}&start=${formattedDatePicker(data.rangeDates.start)}`)) : await axios.get(url)
    return {
        data: response.data
    }
})

export const getInvoices = createAsyncThunk('appInvoices/getInvoices', async (data) => {
    const response = data?.rangeDates?.start !== undefined ? await axios.get(CFDI_ENDPOINT.concat(`?end=${formattedDatePicker(data?.rangeDates?.end)}&start=${formattedDatePicker(data?.rangeDates?.start)}`)) : await axios.get(CFDI_ENDPOINT)
    return {
        data: response.data
    }
})

export const getInvoiceFile = createAsyncThunk('appInvoice/getInvoiceFile', async (data) => {
    const response = await axios.get(CFDI_ENDPOINT.concat(`get-file/?folio_fiscal=${data.folio_fiscal}&file_type=${data.file_type}`), { responseType: 'blob' })
    return {
        data: response.data
    }
})

export const getRegisteredByUser = createAsyncThunk('appInvoice/getRegisteredByUser', async (data) => {
    const response = await axios.get(CFDI_ENDPOINT.concat(data?.rangeDates?.start !== undefined ? `get-registered-by-users/?end=${formattedDatePicker(data?.rangeDates?.end)}&start=${formattedDatePicker(data?.rangeDates?.start)}` : `get-registered-by-users/`))
    return {
        data: response.data
    }
})

export const newXML = createAsyncThunk('appInvoice/addXml', async (xml) => {
    const response = await axios.post(CFDI_READ_XML_ENDPOINT, xml, config)
    return response.data
})

export const updateIntermediaryInvoice = createAsyncThunk('appInvoice/updateIntermediaryInvoice', async (data) => {
    const response = await axios.put(CFDI_ENDPOINT.concat(`update-intermediary/?folio_fiscal=${data.folio_fiscal}`), parseInt(data.intermediary_id))
    return response.data
})

export const cancelCFDI = createAsyncThunk('appInvoice/cancelCFDI', async (folio) => {
    const response = await axios.put(CFDI_ENDPOINT.concat(`cancel/?folio_fiscal=${folio}`))
    return response.data
})

export const addInvoice = createAsyncThunk('appInvoices/addInvoice', async (invoice, { dispatch }) => {
    const response = await axios.post(CFDI_ENDPOINT, invoice)
    await dispatch(getInvoice())
    await dispatch(getInvoices())
    await dispatch(getRegisteredByUser())
    return {
        data: response.data
    }
})

export const deleteInvoice = createAsyncThunk('appInvoices/deleteInvoice', async (folio, { dispatch }) => {
    const response = await axios.delete(CFDI_ENDPOINT.concat(`delete/?folio_fiscal=${folio}`))
    await dispatch(getInvoices())
    return {
        data: response.data
    }
})

export const appInvoiceSlice = createSlice({
    name: 'appInvoice',
    initialState: {
        data: [],
        dataInvoice: [],
        intermediaryInvoice: [],
        companyInvoice: [],
        clientInvoice: [],
        invoiceCount: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getInvoice.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
        builder.addCase(getInvoices.fulfilled, (state, action) => {
            state.dataInvoice = action.payload.data
        })
        builder.addCase(getInvoiceByIntermediary.fulfilled, (state, action) => {
            state.intermediaryInvoice = action.payload.data
        })
        builder.addCase(getInvoiceByCompany.fulfilled, (state, action) => {
            state.companyInvoice = action.payload.data
        })
        builder.addCase(getInvoiceByClient.fulfilled, (state, action) => {
            state.clientInvoice = action.payload.data
        })
        builder.addCase(getRegisteredByUser.fulfilled, (state, action) => {
            state.invoiceCount = action.payload.data
        })
    }
})

export default appInvoiceSlice.reducer