import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { CASH_EXPENSES_PREVIEW } from '../../../../../../../utility/SourceAPI'

export const getCashExpensePending = createAsyncThunk('appCashExpenses/getCashExpensePending', async () => {
    const response = await axios.get(CASH_EXPENSES_PREVIEW)
    return {
        data: response.data
    }
})

export const addCashExpensePending = createAsyncThunk('appCashExpenses/addCashExpensePending', async (cashExpense, { dispatch }) => {
    const response = await axios.post(CASH_EXPENSES_PREVIEW.concat('direct_expense'), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})
export const addCashCreditPending = createAsyncThunk('appCashExpenses/addCashCreditPending', async (cashExpense, { dispatch }) => {
    const response = await axios.post(CASH_EXPENSES_PREVIEW.concat('cash_credit'), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})
export const addCashBoxPending = createAsyncThunk('appCashExpenses/addCashBoxPending', async (cashExpense, { dispatch }) => {
    const response = await axios.post(CASH_EXPENSES_PREVIEW.concat('cash_box'), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})

export const deleteCashExpensePending = createAsyncThunk('appCashExpenses/deleteCashExpensePending', async (cashExpense, { dispatch }) => {
    const response = await axios.delete(CASH_EXPENSES_PREVIEW.concat(cashExpense))
    await dispatch(getCashExpensePending())
    return response.data
})

export const approveCashExpensePending = createAsyncThunk('appCashExpenses/approveCashExpensePending', async (cashExpense, { dispatch }) => {
    const response = await axios.put(CASH_EXPENSES_PREVIEW.concat(`approve/direct_expense/${cashExpense.id}`), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})

export const approveCashCreditPending = createAsyncThunk('appCashExpenses/approveCashExpensePending', async (cashExpense, { dispatch }) => {
    const response = await axios.put(CASH_EXPENSES_PREVIEW.concat(`approve/cash_credit/${cashExpense.id}`), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})

export const approveCashFlowPending = createAsyncThunk('appCashExpenses/approveCashExpensePending', async (cashExpense, { dispatch }) => {
    const response = await axios.put(CASH_EXPENSES_PREVIEW.concat(`approve/cash_box/${cashExpense.id}`), cashExpense)
    await dispatch(getCashExpensePending())
    return response.data
})

export const cashExpensePendingSlice = createSlice({
    name: 'appCashExpensesPending',
    initialState: {
        data: []
    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getCashExpensePending.fulfilled, (state, action) => {
            state.data = action.payload.data
        })
    }
})

export default cashExpensePendingSlice.reducer